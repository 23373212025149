import { ReactElement, useEffect, useCallback, useMemo, memo, useRef, forwardRef } from 'react'
import { TableVirtuoso, TableComponents } from 'react-virtuoso'

// @mui imports
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Pagination from '@mui/material/Pagination'

// KN imports
import KNDataTableRow from './KNDataTableRow'
import { KNDataTableProps, KNDataTableColumn, KNDataTableAction } from './types'

const KNDataTable = <T extends object>({
  tableRef,
  columns,
  actions,
  data,
  getRowId,
  page = 0,
  pageCount = 1,
  useWindowScroll = true,
  onRowClick,
  onUpdate,
  onPageChange,
  overscan = 300,
  sx,
  tableSx,
}: KNDataTableProps<T>): ReactElement => {
  const primaryActions: KNDataTableAction<T>[] = actions?.filter((action) => !action.secondary) ?? []
  const secondaryActions: KNDataTableAction<T>[] = actions?.filter((action) => action.secondary) ?? []

  const VirtuosoTableComponents: TableComponents<T> = useMemo(
    () => ({
      Scroller: forwardRef<HTMLDivElement>((props, ref) => <TableContainer {...props} ref={ref} />),
      Table: (props) => <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed', ...tableSx }} />,
      TableHead,
      TableRow: ({ item: row, ...props }) => (
        <TableRow
          {...props}
          hover={true}
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            onRowClick?.(row)
          }}
          sx={
            onRowClick && {
              cursor: 'pointer',
            }
          }
          className="hoverable"
        />
      ),
      TableBody: forwardRef<HTMLTableSectionElement>((props, ref) => <TableBody {...props} ref={ref} />),
    }),
    []
  )

  const fixedHeaderContent = useCallback(() => {
    // calculate fixed width of actions column
    const actionsWidth = 2 + ((primaryActions.length ?? 0) + ((secondaryActions.length ?? 0) > 0 ? 1 : 0)) * 2
    return (
      <TableRow>
        {columns.map((column) => (
          <TableCell key={column.name} sx={column.sx}>
            {column.label}
          </TableCell>
        ))}
        {(primaryActions || secondaryActions) && <TableCell sx={{ width: `${actionsWidth}rem` }} />}
      </TableRow>
    )
  }, [columns, actions])

  const itemContent = useCallback(
    (_index: number, row: T) => (
      <KNDataTableRow columns={columns} row={row} primaryActions={primaryActions} secondaryActions={secondaryActions} />
    ),
    [columns, actions]
  )

  const handlePageChange = useCallback((event: React.ChangeEvent<unknown>, page: number) => {
    onPageChange?.(page - 1) // NOTE: backend pagination is 0-index based
  }, [])

  return (
    <Box sx={sx} data-guide="datatable">
      <TableVirtuoso
        ref={tableRef}
        data={data}
        components={VirtuosoTableComponents}
        fixedHeaderContent={fixedHeaderContent}
        itemContent={itemContent}
        useWindowScroll={useWindowScroll}
        overscan={overscan}
      />

      {pageCount > 1 && (
        <Pagination
          count={pageCount}
          page={page + 1} // NOTE: backend pagination is 0-index based
          onChange={handlePageChange}
          sx={{
            marginTop: 2,
          }}
        />
      )}
    </Box>
  )
}

// NOTE: additional syntax for memoizing component with generic props
// https://github.com/DefinitelyTyped/DefinitelyTyped/issues/37087#issuecomment-656596623
export default memo(KNDataTable) as typeof KNDataTable
