import { useState, useEffect, useCallback, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { parseISO, isFuture } from 'date-fns'

// @mui imports
import Stack from '@mui/material/Stack'
import Chip from '@mui/material/Chip'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import WarningIcon from '@mui/icons-material/Warning'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

// KN imports
import { sleep } from 'global/helpers/sleep'
import { analyticsEvent } from 'global/helpers/analytics'
import { zonedDate, relativeDate } from 'global/helpers/dateFormatters'
import { getRouteName } from 'global/helpers/activeRoute'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNCaption from 'components/KN_Molecules/KNCaption/KNCaption'
import KNDetailsPopover from 'components/KN_Molecules/KNDetailsPopover/KNDetailsPopover'
import KNDetailsPopoverSection from 'components/KN_Molecules/KNDetailsPopover/KNDetailsPopoverSection'
import KNDetailsPopoverList from 'components/KN_Molecules/KNDetailsPopover/KNDetailsPopoverList'
import KNDetailsPopoverMap from 'components/KN_Molecules/KNDetailsPopover/KNDetailsPopoverMap'
import KNDetailsPopoverMapLoader from 'components/KN_Molecules/KNDetailsPopover/KNDetailsPopoverMapLoader'
import { KNTrackingIcon, KNTrackingWarningIcon } from 'components/KN_Molecules/KNIcon/KNMaterialIcon'
import { MapMarker } from 'components/KN_Molecules/KNMap/types'
import usePopoverState from 'components/KN_Molecules/KNDetailsPopover/usePopoverState'
import { TripData } from 'screens/TripDashboard/TripDashboard.types'
import { positionDataTransformer, groupGeoPointsBySpeed } from './TripDetails.helpers'
import { getTripLegs, getTripGeofences, getTripVehiclePositions } from './TripDetails.service'
import { LegData, StopData, Geofence, GeoPoint, GeoPointsGroup } from './TripDetails.types'

export interface TrackingProps {
  trip: TripData
  preloadedLegs?: LegData[]
  weblinkToken?: string
}

const getBaseMarkers = (legs: LegData[]): MapMarker[] =>
  legs.reduce((markers: MapMarker[], leg) => {
    leg.wayPoints.map((stop) => {
      if (stop.geoPoint) {
        markers.push({
          id: stop.wayPointCid,
          latitude: stop.geoPoint.latitude,
          longitude: stop.geoPoint.longitude,
          type: stop.type,
        })
      }
    })
    return markers
  }, [])

const Tracking = ({ trip, preloadedLegs, weblinkToken }: TrackingProps): ReactElement | null => {
  const { t } = useTranslation()
  const location = useLocation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [loading, setLoading] = useState(true)
  const [hasEta, setHasEta] = useState(false)
  const [markers, setMarkers] = useState<MapMarker[]>([])
  const [geofences, setGeofences] = useState<Geofence[]>([])
  const [geoPoints, setGeoPoints] = useState<GeoPoint[]>([])
  const [groupedGeoPoints, setGroupedGeoPoints] = useState<GeoPointsGroup[]>([])

  const hasTrackingError = !trip.lastTrackedTimestamp

  const {
    anchorEl: trackingDetailsAnchorEl,
    open: trackingDetailsOpen,
    handleClick: handleTrackingDetailsClick,
    handleClose: handleTrackingDetailsClose,
  } = usePopoverState()

  const handleTrackingDetailsClickWrapper = useCallback(
    (event: React.MouseEvent<HTMLElement>): void => {
      handleTrackingDetailsClick(event)
      analyticsEvent('polestar_cs_leg_tracking_popover', [getRouteName(location.pathname)])
    },
    [location]
  )

  const fetchData = useCallback(async (): Promise<void> => {
    setLoading(true)
    try {
      const [legs, vehiclePositionsData, geofencesData] = await Promise.all([
        preloadedLegs ?? getTripLegs(trip.entityId, weblinkToken),
        getTripVehiclePositions(trip.entityId, weblinkToken),
        getTripGeofences(trip.entityId, weblinkToken),
      ])
      const vehiclePositions = positionDataTransformer(vehiclePositionsData)
      const groupedVehiclePositions = groupGeoPointsBySpeed(vehiclePositions)

      setHasEta(
        isFuture(parseISO(trip.calculatedEta ?? '')) &&
          trip.shipmentNumbers.length === 1 &&
          legs.reduce(
            (hasCustoms, leg) => hasCustoms ?? leg.wayPoints.filter((stop) => stop.type === 'CUS').length === 0,
            false
          )
      )

      if (vehiclePositions.length > 0) {
        const lastVehiclePosition = vehiclePositions[vehiclePositions.length - 1]
        setMarkers([
          {
            id: 'vehicle',
            latitude: lastVehiclePosition.latitude,
            longitude: lastVehiclePosition.longitude,
            type: 'VEHICLE',
          },
          ...getBaseMarkers(legs),
        ])
        setGeoPoints(vehiclePositions)
        setGroupedGeoPoints(groupedVehiclePositions)
      } else {
        setMarkers(getBaseMarkers(legs))
      }
      setGeofences(geofencesData)
    } catch (error) {
      //
    }

    // NOTE: without this sleep() there are issues with the map
    // where markers don't show up for some reason
    // TODO: something to investigate further
    await sleep(100)
    setLoading(false)
  }, [trip, preloadedLegs, weblinkToken])

  useEffect(() => {
    if (trackingDetailsOpen) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchData()
    }
  }, [trackingDetailsOpen])

  if (!trip.tracked || isFuture(parseISO(trip.earlyPickUpDate))) {
    return null
  }

  return (
    <>
      <IconButton
        onClick={handleTrackingDetailsClickWrapper}
        size="small"
        sx={{
          margin: '-5px 0 !important',
          color: hasTrackingError ? 'warning.main' : 'primary.main',
        }}
      >
        {hasTrackingError ? (
          <KNTrackingWarningIcon data-test="tracking-icon" />
        ) : (
          <KNTrackingIcon data-test="tracking-icon" />
        )}
      </IconButton>

      <KNDetailsPopover
        open={trackingDetailsOpen}
        onClose={handleTrackingDetailsClose}
        anchorEl={trackingDetailsAnchorEl}
        rightAligned={true}
        sx={{
          maxHeight: 'auto',
        }}
      >
        {hasTrackingError && (
          <KNDetailsPopoverSection
            items={[
              (
                <KNCaption
                  key="warning"
                  color="inherit"
                  icon={<WarningIcon />}
                  sx={{
                    paddingX: 2,
                    paddingY: 0.25,
                  }}
                >
                  {t('screens.cs.trip_dashboard.card.tracking_warning')}
                </KNCaption>
              ),
            ]}
          />
        )}
        {hasEta && (
          <KNDetailsPopoverList
            items={[
              {
                label: t('screens.cs.trip_details.tracking_details.eta'),
                value: zonedDate(trip.calculatedEta!, 'full') ?? undefined,
              },
            ]}
          />
        )}
        {loading ? (
          <KNDetailsPopoverMapLoader />
        ) : (
          <KNDetailsPopoverMap markers={markers} geoPoints={geoPoints} groupedGeoPoints={groupedGeoPoints} zoom={6} />
        )}
      </KNDetailsPopover>
    </>
  )
}

export default Tracking
