import { Fragment, useState, useEffect, useCallback, useMemo, ReactElement } from 'react'
import { GoogleMap, MarkerClusterer, Polyline, useJsApiLoader } from '@react-google-maps/api'
import { UseLoadScriptOptions } from '@react-google-maps/api/dist/useJsApiLoader'
import { throttle } from 'lodash'
import { parseISO, isThisYear, isSameDay, formatDistance } from 'date-fns'
import i18n from 'i18n'

// @mui imports
import theme from 'assets/theme'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'

// KN imports
import { zonedDate, relativeDate } from 'global/helpers/dateFormatters'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import { encodeIconToDataUrl, KNClusterMapIcon } from 'components/KN_Molecules/KNIcon/KNMapIcon'
import { mapStyles } from './KNMap.styles'
import KNMapMarker from './KNMapMarker'
import KNMapTooltip from './KNMapTooltip'
import { getIconDefaultColor, getDistance, getPolylineOptions, getHeadingDate } from './KNMap.helpers'
import { KNMapProps, MapMarker, MapMarkerState } from './types'
import { getEstimatedSpeedLabel, getEstimatedSpeedColor } from 'screens/TripDetails/TripDetails.helpers'
import { GeoPoint } from 'screens/TripDetails/TripDetails.types'

const loaderOptions: UseLoadScriptOptions = {
  id: 'google-map-script',
  googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY || '',
}

const getHeadingTooltip = (geoPoint?: GeoPoint): ReactElement | null => {
  if (!geoPoint) {
    return null
  }
  const estimatedSpeedLabel = getEstimatedSpeedLabel(geoPoint.estimatedSpeed)
  return (
    <>
      <KNTypography>{relativeDate(geoPoint.lastTimestamp || geoPoint.timestamp!)}</KNTypography>
      <KNTypography component="p" variant="textMD">{getHeadingDate(geoPoint.timestamp!, geoPoint.lastTimestamp)}</KNTypography>
      {(geoPoint.estimatedSpeed ?? 0) > 0 && <KNTypography component="p" variant="textMD" color="primary.light">{i18n.t(`screens.cs.trip_details.map.${estimatedSpeedLabel}`)}</KNTypography>}
    </>
  )
}

const getTrackingStartedTooltip = (geoPoint: GeoPoint): ReactElement => (
  <>
    <KNTypography>{relativeDate(geoPoint.timestamp!)}</KNTypography>
    <KNTypography component="p" variant="textMD">{getHeadingDate(geoPoint.timestamp!)}</KNTypography>
    <KNTypography component="p" variant="textMD" color="primary.light">{i18n.t('screens.cs.trip_details.map.tracking_started')}</KNTypography>
  </>
)

const getClusterStyles = (cluster: string) => {
  const baseStyle = {
    textColor: 'white',
    textSize: 12,
    fontFamily: theme.typography.fontFamily,
    height: 40,
    width: 40,
  }
  const clusterColors = {
    vehicles: getIconDefaultColor('VEHICLE'),
    geofences: getIconDefaultColor('GEOFENCE'),
    dwells: getIconDefaultColor('DWELL'),
  }
  return [
    {
      ...baseStyle,
      url: encodeIconToDataUrl(<KNClusterMapIcon color={clusterColors[cluster] || undefined} />),
    },
    {
      ...baseStyle,
      url: encodeIconToDataUrl(<KNClusterMapIcon color={clusterColors[cluster] || undefined} state={MapMarkerState.Muted} />),
    }
  ]
}

const KNMap = <T extends object>({
  markers,
  geoPoints,
  groupedGeoPoints,
  zoom = 10,
  withHeading = false,
  onMarkerClick,
  center,
}: KNMapProps): ReactElement | null => {
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [headingMarker, setHeadingMarker] = useState<MapMarker | null>(null)
  const [clusters, setClusters] = useState<string[]>([])
  const { isLoaded } = useJsApiLoader(loaderOptions)
  const defaultMaxZoom = 17

  const handleMapLoad = useCallback((mapInstance: google.maps.Map) => {
    setMap(mapInstance)
  }, [])

  const handleMapUnmount = useCallback((mapInstance: google.maps.Map) => {
    setMap(null)
  }, [])

  const handleMarkerClick = useCallback((marker: MapMarker) => {
    if (!map) {
      return
    }
    onMarkerClick?.(marker, map)
  }, [map])

  const handleHeadingMove = (event: google.maps.MapMouseEvent) => {
    if (!withHeading || !geoPoints) {
      return
    }
    const cursorPosition = event.latLng
    if (!cursorPosition) {
      setHeadingMarker(null)
      return
    }
    const cursorGeoPoint: GeoPoint = {
      latitude: cursorPosition.lat(),
      longitude: cursorPosition.lng(),
    }
    let shortestDistance = Infinity
    let closestGeoPoint: GeoPoint | null = null
    for (const geoPoint of geoPoints) {
      const distance = getDistance(cursorGeoPoint, geoPoint)
      if (distance < shortestDistance) {
        shortestDistance = distance
        closestGeoPoint = geoPoint
      }
    }
    // ignore if closest one is more than 100km away from cursor
    if (!closestGeoPoint || shortestDistance > 100) {
      setHeadingMarker(null)
      return
    }
    // ignore if it's a first or last geopoint
    const firstPosition = geoPoints[0]
    const lastPosition = geoPoints[geoPoints.length-1]
    if (closestGeoPoint.timestamp === firstPosition.timestamp || closestGeoPoint.timestamp === lastPosition.timestamp) {
      setHeadingMarker(null)
      return
    }

    setHeadingMarker({
      id: 'heading',
      latitude: closestGeoPoint.latitude,
      longitude: closestGeoPoint.longitude,
      type: 'HEADING',
      color: getEstimatedSpeedColor(getEstimatedSpeedLabel(closestGeoPoint.estimatedSpeed)),
      heading: closestGeoPoint.heading,
      tooltip: getHeadingTooltip(closestGeoPoint) ?? undefined,
    })
  }
  const handleHeadingMoveThrottled = useMemo(() => throttle(handleHeadingMove, 200, { leading: true, trailing: false }), [handleHeadingMove, withHeading])

  useEffect(() => {
    if (!map || !withHeading) {
      return
    }
    const mouseMoveEvent = google.maps.event.addListener(map, 'mousemove', handleHeadingMoveThrottled)
    return () => {
      google.maps.event.removeListener(mouseMoveEvent)
    }
  }, [map, withHeading])

  useEffect(() => {
    if (!geoPoints) {
      return
    }
    const firstPosition = geoPoints[0]
    if (firstPosition) {
      markers.push({
        id: 'first_position',
        latitude: firstPosition.latitude,
        longitude: firstPosition.longitude,
        type: 'FIRST_POSITION',
        cluster: 'markers',
        heading: firstPosition.heading,
        tooltip: getTrackingStartedTooltip(firstPosition),
      })
    }
  }, [geoPoints])

  useEffect(() => {
    if (!map) {
      return
    }
    const bounds = new google.maps.LatLngBounds()
    markers.filter((marker) => marker.state !== MapMarkerState.Muted).map((marker) => {
      bounds.extend({
        lat: marker.latitude,
        lng: marker.longitude,
      })
    })
    if (center) {
      map.setOptions({ center: new google.maps.LatLng(center.latitude, center.longitude) })
      map.setZoom(zoom)
    } else {
      // temporarily limit maxZoom, because fitBounds() can zoom in too much with a single marker
      map.setOptions({ maxZoom: 10 })
      map.fitBounds(bounds, { top: 52, right: 52, bottom: 16, left: 16 })
      map.setOptions({ maxZoom: defaultMaxZoom })
    }
    const uniqueClusters = Array.from(markers.reduce((clusters: Set<string>, marker: MapMarker) => {
      if (marker.cluster !== undefined) {
        clusters.add(marker.cluster)
      }
      return clusters
    }, new Set()))
    setClusters(uniqueClusters)
  }, [map, markers, center])

  if (!isLoaded) {
    return null
  }

  return (
    <GoogleMap
      mapContainerStyle={{
        width: '100%',
        height: '100%'
      }}
      zoom={zoom}
      onLoad={handleMapLoad}
      onUnmount={handleMapUnmount}
      options={{
        backgroundColor: 'transparent',
        mapTypeControl: true,
        scaleControl: false,
        streetViewControl: false,
        controlSize: 32,
        styles: mapStyles,
        maxZoom: defaultMaxZoom,
      }}
    >
      {map && <KNMapTooltip map={map} markers={headingMarker ? [...markers, headingMarker] : markers} />}

      {clusters.map((cluster) => {
        const clusterMarkers = markers.filter((marker) => marker.cluster === cluster)
        return (
          <MarkerClusterer
            key={cluster}
            imageSizes={[40, 40]}
            styles={getClusterStyles(cluster)}
            calculator={(markerInstances) => {
              // this method is called for each visible cluster and returns text and style index to apply for that cluster
              // markerInstances is a list of Google's map markers inside given cluster

              // figure out if all markers inside given cluster have a MapMarkerState.Muted state
              const onlyMuted = markerInstances.map((instance) => {
                // instance is a Google map marker, but it has no direct reference to internal MapMarker
                // relatedMarkers is a list of MapMarkers that were found based on matching positions
                const relatedMarkers = clusterMarkers.filter((marker) => {
                  const position = instance.getPosition()
                  return marker.latitude === position?.lat() && marker.longitude === position?.lng()
                })
                return relatedMarkers.every((marker) => marker.state === MapMarkerState.Muted)
              }).every((value) => value === true)
              return {
                text: markerInstances.length.toString(),
                // 1-based index of style to use for given cluster
                // styles that can be used are defined in getClusterStyles()
                index: onlyMuted ? 2 : 1,
              }
            }}
            averageCenter
            maxZoom={12}
            minimumClusterSize={2}
            gridSize={32}
          >
            {(clusterer) =>
              <>
                {clusterMarkers.map((marker) => (
                  <KNMapMarker key={marker.id} marker={marker} clusterer={clusterer} onMarkerClick={handleMarkerClick} />
                ))}
              </>
            }
          </MarkerClusterer>
        )
      })}
      {markers.filter((marker) => !marker.cluster).map((marker) => (
        <KNMapMarker key={marker.id} marker={marker} onMarkerClick={handleMarkerClick} />
      ))}

      {groupedGeoPoints?.map((geoPointsGroup, index) => (
        <Fragment key={index}>
          {geoPointsGroup.label === 'vehicle_slow' && (
            <Polyline
              path={geoPointsGroup.geoPoints.map((geoPoint) => {
                return {
                  lat: geoPoint.latitude,
                  lng: geoPoint.longitude,
                }
              })}
              options={{
                ...getPolylineOptions(geoPointsGroup.label),
                strokeWeight: 16,
                strokeOpacity: 0.2,
              }}
            />
          )}
          <Polyline
            path={geoPointsGroup.geoPoints.map((geoPoint) => {
              return {
                lat: geoPoint.latitude,
                lng: geoPoint.longitude,
              }
            })}
            options={getPolylineOptions(geoPointsGroup.label)}
          />
        </Fragment>
      ))}

      {!groupedGeoPoints && geoPoints && (
        <Polyline
          path={geoPoints.map((geoPoint) => {
            return {
              lat: geoPoint.latitude,
              lng: geoPoint.longitude,
            }
          })}
          options={getPolylineOptions()}
        />
      )}

      {headingMarker && <KNMapMarker marker={headingMarker} />}
    </GoogleMap>
  )
}

export default KNMap
