export const getTheHulkAnnouncements = async (): Promise<any> => {
  if (!process.env.REACT_APP_BLUDIT_URL || !process.env.REACT_APP_BLUDIT_TOKEN) {
    throw new Error('Missing config values')
  }
  const bludItEndpoint = `${process.env.REACT_APP_BLUDIT_URL}/categories/announcements?token=${process.env.REACT_APP_BLUDIT_TOKEN}`
  const response = await fetch(bludItEndpoint)
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`)
  }
  return response
}
