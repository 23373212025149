import { useContext, useEffect, useState, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

// @mui imports //
import Alert from '@mui/material/Alert'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Box from '@mui/material/Box'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Container from '@mui/material/Container'
import DangerousIcon from '@mui/icons-material/Dangerous'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import { Theme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { SystemStyleObject } from '@mui/system'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

// KN imports //
import theme from 'assets/theme'
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'
import KNLoader from 'components/KN_Molecules/KNLoader/KNLoader'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

// Modules //
import TheHulk from 'modules/TheHulk/TheHulk'

// Functional //
import { analyticsEvent, analyticsPageView } from 'global/helpers/analytics'
import { getLogo } from 'global/helpers/getLogo'
import { validateEmail } from './Login.helpers'

// Context //
import { UserContext } from 'context/authentication/UserContext'

// Data //
import { loginTranslations } from './Login.data'

const Login = (): ReactElement => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  // Screen specific
  const [screenState, setScreenState] = useState<'logIn' | 'forgotPassword' | 'passwordReset' | 'loading'>('logIn')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const handleTogglePasswordVisibility = (): void => {
    setShowPassword(!showPassword)
  }
  const [forgottenEmail, setForgottenEmail] = useState('')
  const [errorMessageOpen, setErrorMessageOpen] = useState(false)

  // Data
  const { t } = useTranslation()
  const { translation } = loginTranslations()

  // Context
  const { loginUser, userLoading, userError, resetUser, userResetResult } = useContext(UserContext)

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault()
    setScreenState('loading')
    await loginUser(email, password)
    analyticsEvent('polestar_user_login')
  }

  const resetPassword = async (): Promise<void> => {
    setScreenState('loading')
    await resetUser(forgottenEmail)
    setScreenState('passwordReset')
    analyticsEvent('polestar_user_reset_password')
  }

  useEffect(() => {
    analyticsPageView('polestar/login')
    analyticsPageView('shipmentinsight/loginpage')
  }, [])

  useEffect(() => {
    if (userError) {
      setScreenState('logIn')
      setErrorMessageOpen(true)
    }
  }, [userError])

  // Reset values by screen change
  useEffect(() => {
    setEmail('')
    setPassword('')
    setForgottenEmail('')
  }, [screenState])

  return (
    <Container maxWidth="xs">
      <TheHulk placement="login" />
      <Box my={10} sx={{ position: 'relative' }}>
        <Box my={3}>
          {/* Login State */}
          {screenState === 'logIn' && (
            <>
              {errorMessageOpen && (
                <Alert variant="filled" severity="error" sx={{ mb: 2 }}>
                  {userError}
                </Alert>
              )}
              <form onSubmit={onSubmit}>
                {getLogo('logo', '100%', '64px')}
                <Box mt={9}>
                  <Stack
                    spacing={2}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <KNTypography variant="body2" color="grey">
                      {translation.applicationDescription}
                    </KNTypography>
                  </Stack>
                </Box>
                <Stack spacing={2} px={{ xs: 0, sm: 2 }}>
                  <Box>
                    <KNTypography
                      variant="subtitle2"
                      mb={0.5}
                      sx={{
                        color: ({ palette: { grey } }: Theme): string => grey[600],
                        fontWeight: ({ typography: { fontWeightLight } }: Theme): number => fontWeightLight,
                      }}
                    >
                      {translation.email}
                    </KNTypography>
                    <TextField
                      variant="outlined"
                      fullWidth
                      type="email"
                      autoComplete="email"
                      inputProps={{
                        'data-test': 'email',
                      }}
                      onChange={(e): void => setEmail(e.target.value)}
                    />
                  </Box>
                  <Box>
                    <KNTypography
                      variant="subtitle2"
                      mb={0.5}
                      sx={{
                        color: ({ palette: { grey } }: Theme): string => grey[600],
                        fontWeight: ({ typography: { fontWeightLight } }: Theme): number => fontWeightLight,
                      }}
                    >
                      {translation.password}
                    </KNTypography>
                    <TextField
                      variant="outlined"
                      fullWidth
                      type={showPassword ? 'text' : 'password'}
                      autoComplete="current-password"
                      value={password}
                      onChange={(e): void => setPassword(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            onClick={handleTogglePasswordVisibility}
                            edge="end"
                            aria-label="toggle password visibility"
                            sx={({ palette: { grey } }): SystemStyleObject<Theme> => ({
                              color: grey[300],
                              transition: 'color 200ms',
                              '&:hover': {
                                color: grey[400],
                              },
                            })}
                          >
                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </IconButton>
                        ),
                      }}
                    />
                  </Box>
                  <Box
                    my={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <KNButton
                      variant="text"
                      color="primary"
                      onClick={(): void => {
                        setScreenState('forgotPassword')
                        analyticsEvent('polestar_forgotten_password_button_clicked')
                      }}
                    >
                      {translation.forgottenPassword}
                    </KNButton>
                  </Box>
                  <Box my={2}>
                    <KNButton
                      dataAttribute="login"
                      type="submit"
                      variant="gradient"
                      color="primary"
                      fullWidth
                      disabled={validateEmail(email)}
                      sx={{
                        fontSize: ({ typography: { size } }: Theme): string => size.md,
                        height: '44px',
                      }}
                    >
                      {translation.loginButton}
                    </KNButton>
                  </Box>
                </Stack>
              </form>
            </>
          )}
          {/* Forgot Password State */}
          {screenState === 'forgotPassword' && (
            <form onSubmit={async (): Promise<void> => await resetPassword()}>
              {getLogo('logo', '100%', '64px')}
              <Box my={3}>
                <Stack
                  spacing={2}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <KNTypography variant="h4" color="black">
                    {translation.forgottenPassword}?
                  </KNTypography>
                  <KNTypography variant="body2" color="grey">
                    {translation.resetDescription}
                  </KNTypography>
                </Stack>
              </Box>
              <Stack spacing={2} px={5}>
                <Box>
                  <KNTypography
                    variant="subtitle2"
                    mb={0.5}
                    sx={{
                      color: ({ palette: { grey } }: Theme): string => grey[600],
                      fontWeight: ({ typography: { fontWeightLight } }: Theme): number => fontWeightLight,
                    }}
                  >
                    {translation.email}
                  </KNTypography>
                  <TextField variant="outlined" fullWidth onChange={(e): void => setForgottenEmail(e.target.value)} />
                </Box>
                <Box my={2}>
                  <KNButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={validateEmail(forgottenEmail)}
                    sx={{
                      fontSize: ({ typography: { size } }: Theme): string => size.md,
                      height: '44px',
                    }}
                  >
                    {translation.resetPasswordButton}
                  </KNButton>
                </Box>
                <KNButton
                  variant="text"
                  color="primary"
                  onClick={(): void => setScreenState('logIn')}
                  startIcon={<ArrowBackIcon />}
                >
                  {translation.backButton}
                </KNButton>
              </Stack>
            </form>
          )}
          {/* Password Reset Message State */}
          {screenState === 'passwordReset' && (
            <Stack
              spacing={2}
              sx={{
                display: 'flex',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              {userResetResult === 'success' ? (
                <CheckCircleIcon color="success" sx={{ height: '100px', width: '100px' }} />
              ) : (
                <DangerousIcon color="error" sx={{ height: '100px', width: '100px' }} />
              )}
              <Box my={3}>
                <KNTypography variant="h4" color="black">
                  {userResetResult === 'success' ? translation.resetSuccessful : translation.resetFail}
                </KNTypography>
                <KNTypography variant="body2" color="grey">
                  {userResetResult === 'success' ? translation.resetSuccessfulMsg : translation.resetFailMsg}
                </KNTypography>
              </Box>
              <KNButton
                variant="text"
                color="primary"
                onClick={(): void => setScreenState('logIn')}
                startIcon={<ArrowBackIcon />}
              >
                {translation.backButton}
              </KNButton>
            </Stack>
          )}
          {/* Loading State */}
          {(userLoading || screenState === 'loading') && (
            <KNLoader>
              <KNTypography> {translation.loading}</KNTypography>
            </KNLoader>
          )}
        </Box>
      </Box>
    </Container>
  )
}

export default Login
