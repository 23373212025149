import { ChartDataset } from 'chart.js'
import { parseISO, differenceInSeconds } from 'date-fns'

// @mui imports
import theme from 'assets/theme'

// KN imports
import { isUat } from 'global/helpers/environment'
import { Annotation, getDatasetColors } from 'global/helpers/charts'
import { getDistance, getHeading } from 'components/KN_Molecules/KNMap/KNMap.helpers'
import { MapMarker } from 'components/KN_Molecules/KNMap/types'
import { TripData } from 'screens/TripDashboard/TripDashboard.types'
import {
  LegData,
  StopData,
  StopsSequenceGroup,
  GeoPoint,
  GeoPointsGroup,
  VehicleTemperatureMetricValue,
  VehiclePositionMetricValue,
  PharmaExtendedInformation,
  StopStatus,
} from './TripDetails.types'

export const isStopsViewAvailable = (trip: TripData): boolean => {
  if (trip.sendingApplication === 'MACADAM') {
    return false
  }
  const countryCode = trip.logicalSender.substring(0, 2)
  const supportedRegions = [
    'AT',
    'BG',
    'CH',
    'CZ',
    'DE',
    'DK',
    'EE',
    'ES',
    'FI',
    'GR',
    'HR',
    'HU',
    'ID',
    'IN',
    'IT',
    'LT',
    'LU',
    'LV',
    'MK',
    'MY',
    'NL',
    'NO',
    'PH',
    'PL',
    'PT',
    'RO',
    'RS',
    'SE',
    'SG',
    'SI',
    'SK',
    'TH',
    'TR',
    'UA',
    'US',
    'VN',
  ]
  if (supportedRegions.includes(countryCode)) {
    return true
  }
  return false
}

export const groupStopsBySequence = (legs: LegData[]): StopsSequenceGroup[] =>
  legs
    .reduce((groups: StopsSequenceGroup[], leg) => {
      // create groups with stops and its related legs based on stop's sequence
      leg.wayPoints.map((stop) => {
        if (!groups[stop.sequence]) {
          groups[stop.sequence] = {
            id: stop.sequence,
            stopLegPairs: [],
            state: 'pending',
          }
        }
        groups[stop.sequence].stopLegPairs.push({
          stop,
          leg,
        })
      })
      return groups
    }, [])
    // ensure that groups are sorted by sequence
    .sort((a, b) => a.id - b.id)
    .map((group) => {
      // sort stops within groups by shipment number
      group.stopLegPairs = group.stopLegPairs.sort((a, b) =>
        a.leg.shipmentNumber === b.leg.shipmentNumber ? 0 : a.leg.shipmentNumber < b.leg.shipmentNumber ? -1 : 1
      )
      // calculate state of all groups
      const stops = group.stopLegPairs.map((stopLegPair) => stopLegPair.stop)
      const inProgressStops = stops.filter(
        (stop) => stop.statuses.length > 0 || (stop.documents && stop.documents.length > 0)
      )
      const completedStops = stops.filter((stop) => stop.availableStatuses.length === 0)
      if (stops.length === completedStops.length) {
        group.state = 'completed'
      } else if (inProgressStops.length > 0) {
        group.state = 'in_progress'
      }
      return group
    })

export const getStopsGroupStateColor = (state: string): string => {
  switch (state) {
    case 'completed':
      return theme.palette.success.main
    case 'in_progress':
      return theme.palette.primary.main
    case 'pending':
    default:
      return theme.palette.primary.light
  }
}

export const temperatureDataTransformer = (data: VehicleTemperatureMetricValue[], earliestPickupTime: number | null, latestDeliveryTime: number | null): ChartDataset<'line'>[] => {
  const colors = getDatasetColors()
  return Object.values(
    data.reduce((datasets: Record<string, ChartDataset<'line'>>, point) => {
      const datasetName = point.sensorId ?? 'dataset'
      if (!datasets[datasetName]) {
        const nextColor: string = colors.pop() ?? theme.palette.white.main
        const fadedColor: string = nextColor.toString() + '33'

        datasets[datasetName] = {
          label: point.sensorName ?? datasetName,
          data: [],
          borderColor: nextColor,
          segment: {
            borderColor: (ctx): string => {
              const value = ctx.p0.parsed.x
              if (earliestPickupTime !== null && value < earliestPickupTime) {
                return fadedColor
              } else if (latestDeliveryTime !== null && value > latestDeliveryTime) {
                return fadedColor
              } else {
                return nextColor
              }
            }
          },
          backgroundColor: nextColor
        }
      }
      datasets[datasetName].data.push({ x: new Date(point.timestamp).getTime(), y: parseFloat(point.value) })
      return datasets
    }, {})
  )
}

export const getPickupAndDeliveryTimes = (legs: LegData[]): { earliestPickup: number | null, latestDelivery: number | null } => {
  const pickupStatuses: StopStatus[] = []
  const deliveryStatuses: StopStatus[] = []
  legs.forEach(leg => {
    if (leg.wayPoints) {
      leg.wayPoints.forEach(waypoint => {
        if (waypoint.statuses.length > 0) {
          if (waypoint.type === 'PUP') {
            pickupStatuses.push(...waypoint.statuses)
          } else if (waypoint.type === 'DEL') {
            deliveryStatuses.push(...waypoint.statuses)
          }
        }
      })
    }
  })
  pickupStatuses.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
  deliveryStatuses.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
  const earliestPickup = pickupStatuses.length === 0 ? null : new Date(pickupStatuses[0].createdAt).getTime()
  const latestDelivery = deliveryStatuses.length === 0 ? null : new Date(deliveryStatuses[deliveryStatuses.length - 1].createdAt).getTime()
  return { earliestPickup, latestDelivery }
}

export const getTemperatureAnnotations = (temperatureRanges: PharmaExtendedInformation[], legs: LegData[]) => {
  const annotations: Annotation[] = []

  const addTemperatureRangeAnnotations = (value: PharmaExtendedInformation) => {
    if(value.minimum && value.maximum) {
      annotations.push({
        drawTime: 'beforeDraw',
        type: 'box',
        yMin: value.minimum,
        yMax: value.maximum,
        backgroundColor: theme.palette.primary.dark,
        borderColor: theme.palette.primary.dark
      })
    }
    if(value.setPoint) {
      annotations.push({
        drawTime: 'beforeDraw',
        type: 'line',
        yMin: value.setPoint,
        yMax: value.setPoint,
        borderColor: theme.palette.info.main,
        borderWidth: 2
      })
    }
  }

  const addWaypointAnnotations = (label: string, status: StopStatus) => {
    const createdAt = new Date(status.createdAt).getTime()
    annotations.push({
      drawTime: 'afterDatasetsDraw',
      type: 'line',
      xMin: createdAt,
      xMax: createdAt,
      borderColor: theme.palette.info.main,
      borderWidth: 1,
      borderDash: [6, 6],
      label: { content: label, display: false, position: 'end' },
      enter({ element }, event) {
        element.label.options.display = true
        return true
      },
      leave({ element }, event) {
        element.label.options.display = false
        return true
      }
    })
  }

  const findNearbyTimes = (statuses: LegStatus[], isWithinTime: (date1: string, date2: string) => boolean): void => {
    if (statuses.length > 1) {
      for (let i = 0; i < statuses.length; i++) {
        for (let j = i + 1; j < statuses.length; j++) {
          const label = isWithinTime(statuses[i].status.createdAt, statuses[j].status.createdAt) ?
            `${statuses[i].shipmentNumber} ${statuses[j].shipmentNumber}` : statuses[i].shipmentNumber
          addWaypointAnnotations(label, statuses[i].status)
        }
      }
    } else {
      const label = statuses[0].shipmentNumber
      addWaypointAnnotations(label, statuses[0].status)
    }
  }

  interface LegStatus {
    status: StopStatus
    shipmentNumber: string
  }

  const processStatuses = (waypoint: StopData, shipmentNumber: string, type: 'PUP' | 'DEL'): void => {
    if (waypoint.statuses && waypoint.statuses.length > 0) {
      waypoint.statuses.forEach(status => {
        const legStatus: LegStatus = { status: status, shipmentNumber: shipmentNumber }
        if (type === 'PUP') {
          pickupStatuses.push(legStatus)
        } else if (type === 'DEL') {
          deliveryStatuses.push(legStatus)
        }
      })
    }
  }

  const isWithinTwoHours = (date1: string, date2: string): boolean => {
    const diff = Math.abs(new Date(date1).getTime() - new Date(date2).getTime())
    return diff <= 2 * 60 * 60 * 1000 // 2 hours
  }

  if (temperatureRanges.length !== 0) {
    temperatureRanges.forEach(addTemperatureRangeAnnotations)
  }

  const pickupStatuses: LegStatus[] = []
  const deliveryStatuses: LegStatus[] = []

  legs.forEach((leg) => {
    if (leg.wayPoints) {
      leg.wayPoints.forEach((waypoint) => {
        processStatuses(waypoint, leg.shipmentNumber, waypoint.type as 'PUP' | 'DEL')
      })
    }
  })

  if (pickupStatuses.length !== 0) {
    findNearbyTimes(pickupStatuses, isWithinTwoHours)
  }

  if (deliveryStatuses.length !== 0) {
    findNearbyTimes(deliveryStatuses, isWithinTwoHours)
  }

  return {
    plugins: {
      annotation: {
        annotations: annotations
      }
    }
  }
}

export const temperatureRangesTransformer = (legs: LegData[]): PharmaExtendedInformation[] =>
  legs.filter((leg) => {
    if (!leg.pharmaExtendedInformation) {
      return false
    }
    if (leg.pharmaExtendedInformation.setPoint === null || leg.pharmaExtendedInformation.minimum === null || leg.pharmaExtendedInformation.maximum === null) {
      return false
    }
    return true
  }).map((leg) => leg.pharmaExtendedInformation!)

export const positionDataTransformer = (data: VehiclePositionMetricValue[]): GeoPoint[] => {
  const geoPoints = data.reduce((geoPoints: GeoPoint[], value: VehiclePositionMetricValue) => {
    const geoPoint: GeoPoint = {
      latitude: parseFloat(value.latitude),
      longitude: parseFloat(value.longitude),
      timestamp: value.timestamp,
      estimatedSpeed: 0,
    }
    // TODO: switch to check of distanceToPrevious value
    if (geoPoints[geoPoints.length-1]?.latitude === geoPoint.latitude && geoPoints[geoPoints.length-1]?.longitude === geoPoint.longitude) {
      geoPoints[geoPoints.length-1].lastTimestamp = geoPoint.timestamp
    } else {
      geoPoints.push(geoPoint)
    }
    return geoPoints
  }, [])
  // speed estimation
  let previousGeoPoint: GeoPoint
  geoPoints.map((geoPoint: GeoPoint) => {
    if (previousGeoPoint?.timestamp && geoPoint.timestamp) {
      geoPoint.distanceToPrevious = getDistance(geoPoint, previousGeoPoint)
      geoPoint.timeToPrevious = differenceInSeconds(parseISO(geoPoint.timestamp), parseISO(previousGeoPoint.lastTimestamp ?? previousGeoPoint.timestamp))
      // geopoints with more than 45 mins time difference will be marked as "interrupted" (speed = -1) and shown differently on a map
      geoPoint.estimatedSpeed = geoPoint.timeToPrevious <= 45*60 ? Math.ceil(geoPoint.distanceToPrevious * (60*60 / geoPoint.timeToPrevious)) : -1
    }
    previousGeoPoint = geoPoint
    return geoPoint
  })
  // heading calculation done on reversed list to more easily grab "next" geopoint
  let nextGeoPoint: GeoPoint
  geoPoints.reverse().map((geoPoint: GeoPoint) => {
    if (nextGeoPoint) {
      geoPoint.heading = getHeading(geoPoint, nextGeoPoint)
    }
    nextGeoPoint = geoPoint
    return geoPoint
  })
  // reverse again for proper order
  return geoPoints.reverse()
}

export const getEstimatedSpeedLabel = (estimatedSpeed?: number): string =>
  (!estimatedSpeed || estimatedSpeed === -1) ? 'interruption' : estimatedSpeed < 30 ? 'vehicle_slow' : estimatedSpeed < 60 ? 'vehicle_medium' : 'vehicle_fast'

export const getEstimatedSpeedColor = (label: string): string => {
  switch (label) {
    case 'vehicle_slow':
      return '#f03e3e'
    case 'vehicle_medium':
      return '#f59f00'
    case 'vehicle_fast':
      return theme.palette.success.main
    default:
      return theme.palette.primary.light
  }
}

export const groupGeoPointsBySpeed = (geoPoints: GeoPoint[]): GeoPointsGroup[] => {
  if (geoPoints.some((geoPoint) => geoPoint.estimatedSpeed == undefined)) {
    return [
      {
        label: 'unknown',
        geoPoints: geoPoints,
      },
    ]
  }
  let previousGroupLabel: string
  return geoPoints.reduce((groups: GeoPointsGroup[], geoPoint) => {
    /* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
    const label = getEstimatedSpeedLabel(geoPoint.estimatedSpeed!)
    const previousGroup = groups[groups.length - 1]
    if (!previousGroup || previousGroup.label != label) {
      const newGroup: GeoPointsGroup = {
        label: label,
        geoPoints: [],
      }
      if (previousGroup) {
        // NOTE: include last geopoint of previous group as a starting point of new one
        newGroup.geoPoints.push(previousGroup.geoPoints[previousGroup.geoPoints.length - 1])
      }
      newGroup.geoPoints.push(geoPoint)
      groups.push(newGroup)
    } else {
      previousGroup.geoPoints.push(geoPoint)
    }
    return groups
  }, [])
}
