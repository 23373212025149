import { ReactElement, useEffect, useState } from 'react'

// @mui material imports
import Alert from '@mui/material/Alert'
import Collapse from '@mui/material/Collapse'

// Types
import { TheHulkMessageProps, TheHulkPlacements } from './TheHulk.type'

// Helpers
import { hulkSmash, setTheHulkMessageRead } from './TheHulk.helpers'

// Data
import { getTheHulkAnnouncements } from './TheHulk.data'

const TheHulk = (props: TheHulkPlacements): ReactElement => {
  const { placement } = props
  const [theHulkMessages, setTheHulkMessages] = useState([])
  const theHulkMessage: TheHulkMessageProps |null = hulkSmash(theHulkMessages, placement)

  const [theHulkOpen, setTheHulkOpen] = useState(false)

  const handleTheHulkClose = (): void => {
    if (theHulkMessage) {
      setTheHulkMessageRead(theHulkMessage.key)
      setTheHulkOpen(false)
    }
  }

  useEffect(() => {
    const fetchTheHulkMessages = async (): Promise<void> => {
      try {
        const response = await getTheHulkAnnouncements()
        const messages = await response.json()
        setTheHulkMessages(messages?.data.pages)
      } catch (error) {
        console.log('error', error)
      }
    }
    void fetchTheHulkMessages()
  }, [])

  useEffect(() => {
    if (theHulkMessage) {
      setTheHulkOpen(true)
    }
  }, [theHulkMessage])

  return (
    <Collapse in={theHulkOpen}>
      {theHulkOpen && (
        <Alert data-test="the-hulk" severity="info" variant="filled" sx={{ marginBottom: 2 }} onClose={handleTheHulkClose}>
          {theHulkMessage?.content}
        </Alert>
      )}
    </Collapse>
  )
}

export default TheHulk
