import { getEnv } from 'global/helpers/environment'
import { TheHulkMessageProps } from './TheHulk.type'

export const cleanHTML = (html: string): string => {
  return html.replace(/<[^>]*>?/g, '')
}

export const setTheHulkMessageRead =(message)=> {
  const readMessages = JSON?.parse(localStorage.getItem('theHulkReadMessages') ?? '[]') as string[]
  // Update the array with the new message key
  const updatedReadMessages = [...readMessages, message]
  // Store the updated array back into localStorage
  localStorage.setItem('theHulkReadMessages', JSON.stringify(updatedReadMessages))
}

export const hulkSmash = (activeMessages: TheHulkMessageProps[], placement: string): TheHulkMessageProps | null => {
  const environment = getEnv()
  if (!activeMessages) {
    return null
  }

  const storedMessages = localStorage.getItem('theHulkReadMessages')
  const readMessages = storedMessages ? (JSON.parse(storedMessages) as string[]) : []
  const unreadMessages = activeMessages.filter((message) => !readMessages.includes(message.key))

  // environment filtering for the messages
  const envFilteredMessages = unreadMessages.filter(message =>
    message.tags.split(',').map(tag => tag.trim().toLowerCase()).filter(tag => tag).includes(environment))

  // placement on page filtering
  const placementFilteredMessages = envFilteredMessages.filter(message =>
    message.tags.split(',').map(tag => tag.trim().toLowerCase()).filter(tag => tag).includes(placement || 'global'))

  if (placementFilteredMessages.length === 0) {
    return null
  }

  const cleanedContent = cleanHTML(placementFilteredMessages[0].content)
  return { ...placementFilteredMessages[0], content: cleanedContent}
}
